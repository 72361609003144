import PropTypes from 'prop-types'

const Logo = ({ width, height, style, textColor, iconColor }) => {
  const dftStyle = {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinejoin: 'round',
    strokeMiterlimit: 2,
  }

  const styles = { ...dftStyle, ...style }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1750 318"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <g>
        <g>
          <path
            fill={textColor}
            d="M283.815,259.35l18.876,-0l0,6.415c0,22.948 -3.907,37.281 -11.721,42.997c-7.813,5.716 -27.471,8.575 -58.973,8.575c-28.212,-0 -46.143,-5.038 -53.792,-15.114c-7.65,-10.076 -11.474,-33.785 -11.474,-71.126c-0,-29.199 0.863,-47.562 2.59,-55.088c1.728,-7.526 6.663,-14.25 14.806,-20.172c9.541,-6.909 30.145,-10.363 61.811,-10.363c21.468,-0 35.923,3.331 43.367,9.993c7.443,6.662 11.165,19.535 11.165,38.617l0.124,4.565l-18.877,-0l-0.123,-5.182c-0,-13.571 -2.2,-22.29 -6.601,-26.156c-4.4,-3.866 -14.332,-5.799 -29.795,-5.799c-27.143,0 -43.716,2.262 -49.721,6.786c-6.004,4.524 -9.006,17.067 -9.006,37.63c-0,47.294 2.015,75.013 6.045,83.155c4.03,8.143 17.807,12.215 41.331,12.215c22.372,-0 36.334,-1.769 41.886,-5.305c5.552,-3.537 8.328,-12.42 8.328,-26.65l-0.246,-9.993Z"
          />
          <path
            fill={textColor}
            d="M419.528,197.662l0,117.947l-17.272,0l1.233,-15.422l-0.37,-0.37c-6.004,11.68 -18.835,17.52 -38.493,17.52c-27.554,-0 -41.331,-13.736 -41.331,-41.208l-0,-78.467l17.273,-0l-0,78.467c-0,10.693 1.768,17.848 5.305,21.467c3.537,3.619 10.487,5.429 20.85,5.429c13.572,-0 22.887,-2.694 27.945,-8.081c5.058,-5.388 7.588,-15.278 7.588,-29.672l-0,-67.61l17.272,-0Z"
          />
          <path
            fill={textColor}
            d="M451.483,197.662l17.272,-0l-0.37,17.766l0.37,0.37c6.663,-13.242 19.494,-19.864 38.494,-19.864c19.987,0 32.119,6.622 36.396,19.864l0.493,-0c7.403,-13.242 20.933,-19.864 40.591,-19.864c27.801,0 41.701,14.189 41.701,42.565l0,77.11l-17.273,0l0,-78.714c0,-10.363 -1.85,-17.396 -5.551,-21.097c-3.702,-3.701 -10.693,-5.552 -20.974,-5.552c-13.654,0 -22.907,2.57 -27.76,7.711c-4.853,5.141 -7.279,14.949 -7.279,29.425l-0,68.227l-17.273,0l0,-77.11l-0.247,-5.428c0,-15.217 -8.677,-22.825 -26.032,-22.825c-23.524,0 -35.286,12.79 -35.286,38.37l0,66.993l-17.272,0l-0,-117.947Z"
          />
          <path
            fill={textColor}
            d="M677.385,147.201l-0,19.37l-17.273,-0l-0,-19.37l17.273,-0Zm-0,50.461l-0,117.947l-17.273,0l-0,-117.947l17.273,-0Z"
          />
          <path
            fill={textColor}
            d="M725.625,230.727l-17.15,-0c0,-13.736 3.105,-22.969 9.315,-27.698c6.21,-4.73 18.363,-7.095 36.458,-7.095c19.658,0 32.941,2.9 39.85,8.698c6.909,5.799 10.364,16.841 10.364,33.127l-0,77.85l-17.273,0l1.357,-12.707l-0.37,-0.124c-6.58,9.706 -19.946,14.559 -40.097,14.559c-29.775,-0 -44.662,-11.968 -44.662,-35.903c-0,-14.229 3.331,-24.058 9.993,-29.487c6.663,-5.428 18.712,-8.143 36.15,-8.143c20.727,0 33.147,4.072 37.259,12.215l0.37,-0.124l0,-14.311c0,-13.49 -1.85,-22.229 -5.552,-26.218c-3.701,-3.989 -11.803,-5.984 -24.305,-5.984c-21.22,0 -31.831,5.964 -31.831,17.89c0,0.576 0.041,1.727 0.124,3.455Zm27.759,26.525c-13.242,0 -21.858,1.399 -25.847,4.195c-3.989,2.797 -5.984,8.801 -5.984,18.013c0,9.541 1.974,15.854 5.922,18.938c3.948,3.085 11.968,4.627 24.059,4.627c24.346,-0 36.519,-7.403 36.519,-22.208c-0,-9.294 -2.365,-15.545 -7.094,-18.753c-4.73,-3.208 -13.921,-4.812 -27.575,-4.812Z"
          />
          <path
            fill={textColor}
            d="M836.416,197.662l16.656,-0l-0.493,16.039l0.493,0.37c5.264,-12.091 18.342,-18.137 39.234,-18.137c16.861,0 28.273,2.961 34.237,8.884c5.963,5.922 8.944,17.272 8.944,34.051l0,76.74l-17.272,0l-0,-79.701c-0,-10.117 -1.933,-16.923 -5.799,-20.418c-3.866,-3.496 -11.309,-5.244 -22.331,-5.244c-24.264,0 -36.396,11.474 -36.396,34.422l0,70.941l-17.273,0l0,-117.947Z"
          />
          <path
            fill={textColor}
            d="M984.715,230.727l-17.15,-0c0,-13.736 3.105,-22.969 9.315,-27.698c6.21,-4.73 18.363,-7.095 36.458,-7.095c19.658,0 32.941,2.9 39.85,8.698c6.909,5.799 10.364,16.841 10.364,33.127l-0,77.85l-17.273,0l1.357,-12.707l-0.37,-0.124c-6.58,9.706 -19.946,14.559 -40.097,14.559c-29.775,-0 -44.662,-11.968 -44.662,-35.903c-0,-14.229 3.331,-24.058 9.993,-29.487c6.663,-5.428 18.712,-8.143 36.15,-8.143c20.727,0 33.147,4.072 37.259,12.215l0.37,-0.124l0,-14.311c0,-13.49 -1.85,-22.229 -5.552,-26.218c-3.701,-3.989 -11.803,-5.984 -24.305,-5.984c-21.22,0 -31.831,5.964 -31.831,17.89c0,0.576 0.041,1.727 0.124,3.455Zm27.759,26.525c-13.242,0 -21.858,1.399 -25.847,4.195c-3.989,2.797 -5.984,8.801 -5.984,18.013c0,9.541 1.974,15.854 5.922,18.938c3.948,3.085 11.968,4.627 24.059,4.627c24.346,-0 36.519,-7.403 36.519,-22.208c-0,-9.294 -2.365,-15.545 -7.094,-18.753c-4.73,-3.208 -13.921,-4.812 -27.575,-4.812Z"
          />
          <path
            fill={textColor}
            d="M1281.19,147.201l0,168.408l-18.999,0l-0,-144.843l0.246,-10.117l-0.493,-0l-1.481,3.948c-0.74,1.974 -1.233,3.249 -1.48,3.825l-3.208,7.896l-57.37,139.291l-19,0l-57.493,-137.687l-3.331,-7.773l-1.481,-3.948c-0.411,-0.905 -0.905,-2.18 -1.48,-3.825l-0.494,0l0.124,4.565l0.123,4.688l-0,143.98l-19,0l-0,-168.408l32.941,-0l44.909,108.941l7.156,17.643l3.578,8.76l3.455,8.759l0.493,0l3.455,-8.759c1.727,-4.195 2.878,-7.115 3.454,-8.76l7.279,-17.52l44.663,-109.064l33.434,-0Z"
          />
          <path
            fill={textColor}
            d="M1394.32,279.954l17.643,-0l0.124,4.318c-0,12.255 -3.722,20.83 -11.166,25.724c-7.444,4.894 -20.501,7.341 -39.172,7.341c-21.714,-0 -35.964,-3.969 -42.75,-11.906c-6.785,-7.937 -10.178,-24.614 -10.178,-50.029c-0,-23.524 3.413,-39.316 10.24,-47.377c6.827,-8.06 20.193,-12.091 40.097,-12.091c21.714,0 35.944,3.496 42.688,10.487c6.745,6.992 10.117,21.715 10.117,44.169l0,9.253l-84.883,0c0,18.589 1.995,30.433 5.984,35.533c3.989,5.099 13.304,7.649 27.945,7.649c13.9,-0 22.927,-1.213 27.081,-3.64c4.154,-2.426 6.23,-7.67 6.23,-15.73l0,-3.701Zm0,-33.559l-0.123,-5.552c0,-12.749 -2.097,-21.035 -6.292,-24.86c-4.195,-3.825 -13.243,-5.737 -27.143,-5.737c-13.983,0 -23.051,2.241 -27.204,6.724c-4.154,4.483 -6.231,14.291 -6.231,29.425l66.993,0Z"
          />
          <path
            fill={textColor}
            d="M1501.78,197.662l0,14.311l-45.402,0l-0,72.175c-0,12.585 5.593,18.877 16.779,18.877c11.104,-0 16.656,-5.634 16.656,-16.903l0.123,-5.798l0.247,-6.539l16.039,-0l0.123,8.76c-0,23.194 -11.022,34.792 -33.065,34.792c-22.783,-0 -34.175,-9.665 -34.175,-28.994l-0,-76.37l-16.286,0l0,-14.311l16.286,-0l-0,-28.377l17.273,0l-0,28.377l45.402,-0Z"
          />
          <path
            fill={textColor}
            d="M1603.57,279.954l17.643,-0l0.124,4.318c-0,12.255 -3.722,20.83 -11.166,25.724c-7.444,4.894 -20.501,7.341 -39.172,7.341c-21.714,-0 -35.964,-3.969 -42.75,-11.906c-6.785,-7.937 -10.178,-24.614 -10.178,-50.029c-0,-23.524 3.413,-39.316 10.24,-47.377c6.827,-8.06 20.192,-12.091 40.097,-12.091c21.714,0 35.944,3.496 42.688,10.487c6.745,6.992 10.117,21.715 10.117,44.169l0,9.253l-84.883,0c0,18.589 1.995,30.433 5.984,35.533c3.989,5.099 13.304,7.649 27.945,7.649c13.9,-0 22.927,-1.213 27.081,-3.64c4.154,-2.426 6.23,-7.67 6.23,-15.73l0,-3.701Zm0,-33.559l-0.123,-5.552c0,-12.749 -2.097,-21.035 -6.292,-24.86c-4.195,-3.825 -13.243,-5.737 -27.143,-5.737c-13.983,0 -23.051,2.241 -27.204,6.724c-4.154,4.483 -6.231,14.291 -6.231,29.425l66.993,0Z"
          />
          <path
            fill={textColor}
            d="M1697.21,195.934c21.796,0 35.943,3.784 42.441,11.351c6.498,7.567 9.747,24.017 9.747,49.351c0,25.333 -3.249,41.783 -9.747,49.35c-6.498,7.567 -20.645,11.351 -42.441,11.351c-21.714,-0 -35.82,-3.784 -42.318,-11.351c-6.498,-7.567 -9.747,-24.017 -9.747,-49.35c0,-25.334 3.249,-41.784 9.747,-49.351c6.498,-7.567 20.604,-11.351 42.318,-11.351Zm-0,14.312c-15.299,0 -24.778,2.488 -28.438,7.464c-3.66,4.977 -5.491,17.952 -5.491,38.926c0,20.973 1.831,33.949 5.491,38.925c3.66,4.976 13.139,7.464 28.438,7.464c15.381,-0 24.901,-2.488 28.561,-7.464c3.661,-4.976 5.491,-17.952 5.491,-38.925c-0,-20.974 -1.83,-33.949 -5.491,-38.926c-3.66,-4.976 -13.18,-7.464 -28.561,-7.464Z"
          />
        </g>
        <path
            fill={iconColor}
          d="M125.942,90.805l0,-11.844l81.725,-0l-0,11.844l-81.725,-0Zm0,-51.719l0,-11.845l123.574,0l-0,11.845l-123.574,-0Zm-66.327,212.404c-16.845,-0 -30.992,-5.725 -42.441,-17.174c-11.449,-11.449 -17.174,-25.596 -17.174,-42.441c-0,-10.792 2.895,-20.991 8.686,-30.598c5.79,-9.607 14.081,-17.568 24.872,-23.885l0,-111.335c0,-6.843 2.501,-12.897 7.502,-18.161c5,-5.264 11.186,-7.896 18.555,-7.896c7.107,0 13.226,2.632 18.359,7.896c5.132,5.264 7.698,11.318 7.698,18.161l0,111.335c10.792,6.317 19.017,14.213 24.676,23.688c5.658,9.475 8.488,19.74 8.488,30.795c-0,16.318 -5.791,30.334 -17.371,42.046c-11.581,11.713 -25.531,17.569 -41.85,17.569Zm-47.771,-59.615l95.148,-0c-0,-8.686 -2.501,-17.174 -7.501,-25.465c-5.001,-8.291 -11.976,-15.069 -20.925,-20.333l-4.738,-2.368l0,-117.652c0,-3.948 -1.447,-7.304 -4.343,-10.067c-2.895,-2.764 -6.185,-4.146 -9.87,-4.146c-3.948,0 -7.304,1.382 -10.067,4.146c-2.764,2.763 -4.146,6.119 -4.146,10.067l0,117.652l-4.737,2.368c-9.739,5.528 -16.977,12.371 -21.714,20.53c-4.738,8.159 -7.107,16.582 -7.107,25.268Z"
        />
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  width: '24px',
  height: '24px',
  style: {},
  color: '#fff',
}

Logo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  textColor: PropTypes.string,
  iconColor: PropTypes.string,
}

export default Logo
